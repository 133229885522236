import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export default function formatDateInputToTimestamp(date: string): Timestamp {
  const x = Timestamp.fromDate(dayjs(date).toDate());

  console.log(x);

  return x;
}
