import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export default function formatProjectDuration(
  startDate: Timestamp,
  endDate: Timestamp,
): string {
  if (!startDate && !endDate) {
    return '';
  }

  const startD = dayjs(startDate.toDate());
  const endD = dayjs(endDate.toDate());

  return `${startD.format('MM/DD/YYYY')} - ${endD.format('MM/DD/YYYY')}`;
}
