import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export default function formatTimestampToDateInput(
  timestamp?: Timestamp,
): string | undefined {
  if (!timestamp) {
    return;
  }

  return dayjs(timestamp.toDate()).format('YYYY-MM-DD');
}
