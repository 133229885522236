import { lazy, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { wrapCreateBrowserRouterV6 } from '@sentry/react';
import { ProtectedRoute, SuspenseLoader } from './components';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const InvitationPage = lazy(() => import('../pages/InvitationPage'));
const RequestPasswordResetPage = lazy(
  () => import('../pages/RequestPasswordResetPage'),
);
const ProjectsPage = lazy(() => import('../pages/ProjectsPage'));
const ProjectPage = lazy(() => import('../pages/ProjectPage'));
const PersonnelPage = lazy(() => import('../pages/PersonnelPage'));
const SettingsPage = lazy(() => import('../pages/SettingsPage'));

function PageViewTracker() {
  const posthog = usePostHog();

  useEffect(() => {
    const unsubscribe = router.subscribe(
      ({ location }: { location: { pathname: string } }) => {
        posthog?.capture('$pageview', { pathname: location.pathname });
      },
    );

    return () => unsubscribe();
  }, [posthog]);

  return null;
}

const sentryCreatedBrowserRouter =
  wrapCreateBrowserRouterV6(createBrowserRouter);

const router = sentryCreatedBrowserRouter([
  {
    path: '/',
    element: (
      <SuspenseLoader>
        <LoginPage />
      </SuspenseLoader>
    ),
  },
  {
    path: '/invitation',
    element: (
      <SuspenseLoader>
        <InvitationPage />
      </SuspenseLoader>
    ),
  },
  {
    path: '/request-password-reset',
    element: (
      <SuspenseLoader>
        <RequestPasswordResetPage />
      </SuspenseLoader>
    ),
  },
  {
    path: '/projects',
    element: (
      <SuspenseLoader>
        <ProtectedRoute>
          <ProjectsPage />
        </ProtectedRoute>
      </SuspenseLoader>
    ),
  },
  {
    path: '/projects/:id/*',
    element: (
      <SuspenseLoader>
        <ProtectedRoute>
          <ProjectPage />
        </ProtectedRoute>
      </SuspenseLoader>
    ),
  },
  {
    path: '/team',
    element: (
      <SuspenseLoader>
        <ProtectedRoute isAdminRoute>
          <PersonnelPage />
        </ProtectedRoute>
      </SuspenseLoader>
    ),
  },
  {
    path: '/settings',
    element: (
      <SuspenseLoader>
        <ProtectedRoute>
          <SettingsPage />
        </ProtectedRoute>
      </SuspenseLoader>
    ),
  },
]);

export default function Router(): JSX.Element {
  return (
    <>
      <PageViewTracker />
      <RouterProvider router={router} />
    </>
  );
}
